.login_btn{
    font-size: 22px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    margin-top: 40px !important;
    margin-bottom: 30px !important;
    letter-spacing: 0px !important;
}
.login_btn:hover{
    background-color: rgb(20, 95, 165) !important;
}
.filter_bttn{
    font-size: 22px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    letter-spacing: 0px !important;
}
.filter_bttn:hover{
    background-color: rgb(20, 95, 165) !important;
}
.clear_btn{
    font-size: 22px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border-radius: 8px !important;
    box-shadow: none !important;
   
    letter-spacing: 0px !important;
    background-color: #fff !important;
    border : 1px solid rgb(24 108 186) !important;
    color: rgb(24 108 186) !important;
}
.clear_btn:hover{
    background-color: rgb(24 108 186) !important;
    color: #fff !important;
}

.forget_link{
    font-size: 15px !important;
    color: #2168F0 !important;
    font-weight: 500 !important;
}
.forget_link:hover{
    color: rgba(2, 30, 57, 1) !important
}
.create_btn{
    color: #2168F0 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-left: 40px !important;
    cursor: pointer;
}
.primary_btn{
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    margin-top: 40px !important;
    margin-bottom: 30px !important;
    align-items: center !important;
}
.primary_btn:hover{
    background-color: rgba(2, 30, 57, 1) !important;
}
.fillter_btn{
    height: 48px !important;
    border-radius: 10px !important;
    border: 1.4px solid #E7E6EC !important;
    color: #333 !important;
}
.outlined_btn{
    margin-left: auto !important;
    margin-bottom: 1rem !important; 
    border-radius: 10px !important;
    background-color: #fff !important;
    border : 1px solid rgb(24 108 186) !important;
}
.outlined_btn:hover{
    background-color: rgb(24 108 186) !important;
    color: #fff !important;
}
.save_btn{
    margin-left: auto !important;
    padding: 0px 10px !important;
    border-radius: 10px !important;
    background-color: #fff !important;
    border : 1px solid rgb(24 108 186) !important;
    color: #2168F0 !important;
    padding: 0 !important;
    font-size: 20px !important;
    text-transform: capitalize !important;
}
.save_btn:hover{
    background-color: rgb(24 108 186) !important;
    color: #fff !important;
}
.filled_btn{
    margin-left: auto !important;
    margin-bottom: 2.5rem !important;
    border-radius: 10px !important;
    background-color: rgb(24 108 186) !important;
    color: #fff !important;
    
}
.filled_btn:hover{
    background-color: rgb(20, 95, 165) !important;
}
.button_icon{
    margin-right: 5px;
    width: 15px; 
    height: 15px;
}
button.w-365{
    width: 300px !important;
    margin: 0px !important;
}

button.create_btn_1.w-365{
    width: 195px !important;
    font-size: 16px !important;
}
.back_btn{
    font-size: 18px !important;
    text-transform: none !important;
    gap: 10px;
    letter-spacing: none !important;
    padding: 5px 22px !important;
}