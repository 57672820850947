.rounded-dot {
    display: inline-block;
    height: 8px;
    width: 8px;
    margin: 0px 4px 0px 0px;
    background: #fff;
    border-radius: 50%;
}
.p-active {
    color: #fff;
    background-color: #5da472e1;
}
.p-inactive {
    color: #fff;
    background-color: #ee0800a9;
}
.p-pending {
    color: #fff;
   background-color: #eec200;
    }
.status{
    padding: 3.5px 10px;
    border-radius: 15px;
    width: auto;
    display: inline-block;
    font-size: 12px !important;
    white-space: nowrap;
    margin: 0;
    color: #fff;
}
.p-followup{
    color: #fff;
    background-color: #6366F1;
 }
.span-active {
    color: #fff;
    padding: 2px 3px;
    border-radius: 15px;
    background-color: #16a34a;
    width: 4.5rem;
    height: 1.55rem;
    margin-left: 10px;
    padding-left: 15px;
    font-size: 13px;
    margin-top: 10px;
}
.span-inactive {
    color: #fff;
    padding: 2px 3px;
    border-radius: 15px;
    background-color: #ee0800;
    width: 4.5rem;
    height: 1.55rem;
    margin-left: 10px;
    padding-left: 15px;
    font-size: 13px;
    margin-top: 10px;
}
.activity-p {
    padding: 12px;
    background-color: #6200EE;
    border-radius: 50px;
    width: fit-content;
    color: #fff;
}
.dot {
    display: inline-block;
    height: 8px;
    width: 8px;
    margin-left: 5px;
    background: #6200EE;
    border-radius: 50%;
    margin-top: 22px;
}
.css-1i7t2wa-MuiBadge-root .MuiBadge-badge {
    top: -5px;
    right: -5px;
    width: 18px !important;
    height: 18px !important;
    border-radius: 50%;
    border: 2px solid;
    border-color: #fff;
    box-shadow: 0 0.5rem 1.25rem rgba(115, 82, 199, 0.7);
    background-image: linear-gradient(135deg, #7352C7, #E44A77);
    color: #fff;
}
.p-active-green {
    color: #fff;
    padding: 2px 10px;
    border-radius: 15px;
    background-color: #16a34a;
    width: 5rem;
}
.reasult_mony.grow{
    color: #15AB12;
}
.reasult_mony.loss{
    color: #E45656;
}

.new{
    background-color: #31c6d4 !important;
}
.under_review{
    background-color: #eeae07 !important;
}
.interviewed{
    background-color: #3d9e3d !important;
}
.offered{
    background-color: #74d416 !important;
}
.rejected{
    background-color: #ff3333 !important;
}

