/* admin */
.admin_navbar{
    background-color: #FFFFFF !important;
   /* height: 63px !important; */
    min-height: 50px !important; 
    display: block !important;
    color: #000 !important;
}
.admin_nav_menus ul{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 80px;
    margin-left: 60px !important;
    margin: 0px ;
}
.admin_nav_menus ul li{
    list-style: none ;
    margin-right: 10px;
}
.admin_nav_menus ul li a{
    white-space: nowrap;
    text-decoration: none !important;
    font-size: 17px;
    font-weight: 600;
    color: #666666;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 25px;
    position: relative;
    height: 100%;
}
.admin_nav_menus ul li a.active{
   /* border-bottom: 1px solid rgb(24 108 186); */
    color: #333333 !important;
}
.admin_nav_menus ul li a.active:hover{
    color: #333333 !important;
}
.profile_name{
    color: #4D4D59 !important;
    font-size: 16px !important;
}
.admin_nav_menus ul li a.active::after{
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: rgb(24 108 186) ;
   color: #FFFFFF;
}
.admin_nav_menus ul li.dropdown_hover:hover {
    background-color: none !important;
    color: #ffffff;
}

.drpdownlinks p a:hover {
    background-color: #FFFFFF !important;
   /* color: rgb(24 108 186)!important; */
}
.admin_nav_menus ul li a.active:hover{
    background-color: #9c999941 !important;
   /* color: rgb(24 108 186)!important;
    */
}
.admin_nav_menus ul li.dropdown_hover a.settings:hover{
    color: rgb(24 108 186) !important;
    background-color: #FFFFFF !important;
}
@media screen and (max-width: 1366px) {
    .admin_nav_menus ul li a {
        font-size: 16px;
        padding: 20px;
   }
    .admin_nav_menus ul {
        margin-left: 0px;
   }
    .admin_nav_menus ul li {
        margin-right: 20px;
   }
}
.css-1jr14eh-MuiToolbar-root {
    min-height: 50px !important;
}
/* .admin_sidenav_container{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
} */
.sidebar_content{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.sidebar_content_top{
    flex-direction: column;
    display: flex;
}
.admin_responsive_logo svg{
    height: 60px;
    width: 190px;
}
.admin_responsive_logo{
   /* text-align: center;
    */
    padding-top: 16px;
    padding-bottom: 16px;
}
.credits_admin_logo{
    height: 70px;
    border-top: 1.4px solid #e7e7e7;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.admin_responsive_links ul li{
    list-style: none !important;
    margin-bottom: 10px;
  /*  border-bottom: 1.4px solid #e7e7e7; */
    
}
.admin_responsive_links ul li a{
    padding:32px !important
}

/* border nav */
.admin_responsive_links li a.active{
    background: rgba(24, 108, 186, 0.11);
   border: 1.5px solid rgb(24 108 186); 
    margin-right: 20px;
    color: #333;
    width: 56% !important;
}
.admin_responsive_links a{
    text-decoration: none !important;
    font-size: 18px;
    font-weight: 600;
    color: #666666;
    display: flex;
    align-items: center;
    gap: 16px;
   /* padding: 15px 24px;  */ 
    position: relative;
}
.admin_responsive_links a svg{
    font-size: 27px !important;
}
div.admin_responsive_links .css-1urr0ul-MuiDivider-root{
    list-style: none !important;
    width: 100% !important 
}

@media screen and (max-width: 1280px) {
    .admin_nav_menus ul li a {
        font-size: 16px;
        width: 95% !important;
        padding: 20px !important;
   }
    .admin_nav_menus ul {
        margin-left: 0px;
   }
    .admin_nav_menus ul li {
        margin-right: 20px;
   }
   .admin_responsive_links li a.active {
    width: 95% !important;
   }
   .admin_responsive_links ul li a{
    padding: 20px !important;
   }
}