.common_table th{
    font-size: 16px !important;
    font-weight: 600 !important;
    color: black !important;
    font-family: "Inter" !important;
    padding: 10px 10px;
}
.common_table td{
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: "Inter" !important;
    padding: 14px 10px;
}
.link{
    text-decoration: none;
    color:blue;    
}
.link:hover{
    
    color:rgb(0, 0, 139);    
}