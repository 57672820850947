.search-input {
    max-width: 240px;
    margin-bottom: 2.5rem;
    margin-left: 1rem;
    border-radius: 24px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.085);
    border: 1px solid grey;
}
.search-input:hover {
    border-radius: 24px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    border: 1px solid black;
}
.from_label {
    font-size: 15px !important;
    color: #666666 !important;
    font-family: Inter !important;
    font-weight: 500 !important;
    margin-bottom: 12px !important;
}

.form_new_style .css-1nqdp0s-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 10px !important;
}

.form_new_style #outlined-basic{
    height: 16px !important;
    font-weight: 600 !important;
}

textarea.form_new_style {
    border-radius: 5px !important;
    padding: 7.5px 4px 7.5px 5px;
    width: 100% !important;
}

.form_new_style{
    width: 100% !important;
}
.form_selct .MuiInputBase-root{
    border-radius: 10px !important;
    height: 53px !important;
}
.verify_otp_verification_title{
    font-size: 24px ;
    font-family: Inter "sans-derif";
    font-weight: 600;
    color: #101010;
    margin: 0;
    margin-bottom: 5px;
}
.verify_otp_verification_subtext{
    font-size: 16px ;
    font-family: Inter "sans-derif";
    font-weight: 500;
    color: #666666;
    margin: 0;
}
.Otp_input_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}
.otp_input{
    width: 73px;
}
.otp_input input{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.searchbar{
    position: relative;
    flex: 1 1 auto;
}
.search{
    min-width: 260px !important;
    max-width: 260px !important;
    margin-bottom: 1.5rem !important;
    margin-left: 1rem !important;
    border: 1px solid transparent;
}
.search:hover{
    position: relative;
    border-radius: 24px !important;
   /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.085) !important;*/
    border: 0.5px solid grey !important;
}
.search_icon{
    position: absolute;
    left: 8px;
    top: 10px;
    color: #A3A3A3;
    font-size: 28px !important;
} 
.search_icon:hover{
    color:grey;
}
.searchbar .css-u7zwh2-MuiInputBase-input-MuiOutlinedInput-input{
    padding-left: 40px !important;
}
.radio_form span{
    font-size: 18px !important;
    font-weight: 500;
    color: #101010 !important;
    font-family: Inter "sans-serif";
}
.select_box{
    width: 390px !important;
}
.select_box .css-13cymwt-control {
    border-radius: 12px !important;
}
.select_box .css-1fdsijx-ValueContainer{
    padding: 8px !important;
}
/* date picker input */
.css-1lemicc-MuiInputBase-root-MuiOutlinedInput-root{
    min-width: 353px !important;
}
.login-input div input{
    font-weight: 600 !important;
    font-size: 15px !important;
}

