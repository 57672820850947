.current_user_session{
    background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     height: 100vh;
     padding: 0px !important;
     /* background-color: #F6F2EB; */
     position: relative;
     display: flex;
     align-items: center;
     justify-content: center;
  }
  .saving_user_session{
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
  }
  .saving_user_register{
     height: 100%;
     padding: 0px !important;
     background-color: #F6F2EB;
     position: relative;
     display: flex;
     align-items: center;
     justify-content: center;
  }
  .saving_user_session_content_div{
     background-color: white;
     width: 48%;
     height: 90vh;
     border-radius: 10px ;
     padding-inline-start: 30px;
  
  }
  .saving_user_register_content_div{
     background-color: white !important;
     width: 975px;
     height: 100%;
     position: absolute;
     right: 0px;
  }
  .saving_user_session_img_div{
     width: 48%;
     height: 90vh;
     padding-inline-end: 30px;
  }
  .img-div{
     width: 525px;
     height: 500px;
     object-fit: contain;
     padding-right: 20px;
  }
  .saving_user_register_img_div{
     width: 40%;
     height: 95vh;
     margin-left: 10px;
     margin-top: 130px;
  }
  .app_logo_savings{
     display: inline-block;
     margin: 30px 0px 0px 0px;
     width: 250px;
  }
  .savinuser_heading{
     margin-top: 80px;
  }
  .savinguser-dashlogo{
     position: absolute;
     top: 60px !important;
     left: 77px;
  }
  .headerbox-registercontent{
     width: 100%;
     height: 80px;
     background-color: #F6F2EB;
  }
  .footerbox-registercontent{
     width: 100%;
     height: 80px;
  }
  .footer-submit{
     display: flex;
     justify-content: space-between;
     padding: 10px 30px;
     margin: 20px 20px;
  }
  .saving-user-profile-container{
     margin: 20px 10px 10px 20px;
     width: 500px;
     height: 105px;
     display: flex;
  }
  .saving-user-profile-container div{
     margin-top: 10px;
     margin-left: 10px;
     padding: 5px;
  }
  .link-savings{
     color: #EC8605 !important;
     cursor: pointer;
     margin-bottom: 0px !important;
  }
  .link-savings:hover{ 
     color: #2168F0 !important;
  }
  
  
  .current_user_session_card{
     /* height: 500px; */
     width: 450px;
     background: #fff;
     border-radius: 10px;
     border: 1.4px solid #E7E6EC;
     box-shadow: 2px 2px 5px 0px #C2C2C21A;
     padding: 24px 54px ;
  
  }
  .app_logo_svg{
     text-align: center;
     margin-top: 16px;
     margin-bottom: 22px;
  }
  .current_session_form{
     display: flex;
     flex-direction: column;
     align-items: center;
     
  }
  .savingsuser-sidebar{
     position: fixed;
     left: 0px;
     background-color: #F6F2EB;
  }
  .custom_grid-saving{
     margin-top: 10px !important;
  }
  .login_greeting{
     font-size:clamp(1.125rem, 0.9276rem + 1.0526vi, 1.875rem) !important;
     font-weight: 600 !important;
     font-family: Inter !important;
  }
  .admin_login_bg{
     background: #f5f5f5 ;
     background-position: right;
     background-repeat: no-repeat;
     background-size: cover;
     width: 500px;
     height: 480px;
    /* height: 100%;
     */
  }
  .admin_logo{
    
     background-position: center;
     background-repeat: no-repeat;
     width: 150px;
     height: 100px;
  }
  .login_card{
     overflow: unset !important;
  }
  .login_label{
     font-size: 16px !important;
     color: #666666 !important;
     font-family: Inter !important;
     font-weight: 500 !important;
     margin-bottom: 12px !important;
  }
  .timer{
     font-size: 14px !important;
     color: #EC8605 !important;
     font-weight: 400 !important;
  }
  .forget_link{
     font-size: 15px !important;
     color: #878787 !important;
     font-weight: 500 !important;
     cursor: pointer !important;
  }
  .forget_link:hover {
     color: #EC8605 !important;
  }
  .forgot:hover {
     color: #EC8605 !important;
  }
  .back_to_login{
     font-size: 15px !important;
     color: #2168F0 !important;
     font-weight: 500 !important;
  }
  .back_to_login:hover{
  color: #333 !important;
  }
  .admin_greetings{
     font-size: 34px;
     font-weight: 600;
     color: #292525;
     margin-bottom: 10px;
  }
  @media screen and (max-width:768px) {
     .admin_greetings{
         font-size: 20px;
    }
  }
  /* new style css */
  .session_card.flex_container_col{
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
  }
  .required{
     color: red;
  }
  .error_text{
     color: red;
     margin-top: 4px;
     margin-left: 3px;
  }
  .session_card{
     background: #FFF;
     width: 500px;
     /* height: 655px; */
     border-radius: 10px;
     padding: 12px 52px;
     border-radius: 25px;
     border: 1px solid #EEE;
     margin-left: auto;
  }
  .infotext{
     font-size: 10px !important;
     color: #fff;
     
   
  }
  .session_wrapper{
     width: 100% !important;
  }
  .admin_form{
    
     margin-top: 30px;
  }
  .admin_form .css-1nqdp0s-MuiInputBase-root-MuiOutlinedInput-root{
     border-radius: 10px !important;
     height: 60px;
  }
  .admin_login_form{
     padding: 30px;
  }
  .session_card .logo{
     font-weight: 600;
     color: #624FBA;
     font-family: HIRANGO_LIGHT, sans-serif;
     font-size: clamp(1.75rem, 1.29rem + 1.0667vw, 2.25rem);
     text-align: center;
     margin: 0;
     margin-top: 16px;
     letter-spacing: 1px;
  }
  .session_card .logo span{
     color: #624FBA;
     font-weight: 600 !important;
     font-family: HIRANGO_BOLD, sans-serif !important;
  }
  .session_form{
     width: 100%;
     margin: auto;
  }
  .session_title h2{
     font-family: Inter !important;
     font-weight: 500 !important;
     font-size: clamp(1.5rem, 1.04rem + 1.0667vw, 2rem);
     color:#171717;
     margin: 0;
     margin-bottom: 6px;
     text-align: center;
  }
  .session_subcont p span{
     font-weight: 600 !important;
     color: #454545 !important;
  }
  .session_title{
     margin-top: 20px;
  }
  .session_title p{
     font-family: SFPRO_MEDIUM ;
     font-size: 16px;
     margin: 0;
     font-weight: 500;
     margin: 0px;
     color:#414141;
     text-align: center;
  }
  .session_login{
     background: #232323 !important;
     font-family: Inter;
     font-family: 700;
     font-size: 20px !important;
     font-weight: 700 !important;
     color: #fff !important;
     text-transform: unset !important;
     border-radius: 10px !important;
     box-shadow: none !important;
     border: 1.5px solid #222 !important;
     letter-spacing: normal !important 
  }
  
  .saving_user_session{
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     height: 100vh;
     padding: 0px !important;
     background-color: #F6F2EB;
     position: relative;
     display: flex;
     align-items: center;
     justify-content: center;
  }
  
  
  .session_login:hover{
     background: #fff !important;
     border: 1.5px solid #222;
     color: #333 !important;
  }
  .text-end{
     text-align: end !important;
  }
  .session_page{
     background-color: #F6F2EB;
     height: 100dvh;
     width: 100%;
  }
  .session_subcont h1{
     font-size: clamp(1.75rem, 0.6rem + 2.6667vw, 3rem);
     font-style: normal;
     font-weight: 600;
     color:#171717;
     font-family: Inter;
     font-family: 700;
     font-weight: 700;
     margin: 0;
  }
  .session_content{
     margin: auto;
  }
  .session_subcont p{
     color: #6E6E6E;
     font-family: Inter;
     font-size: clamp(1.125rem, 0.8375rem + 0.6667vw, 1.4375rem);
     margin: 0px 0;
     font-weight: 400;
     line-height: 34px;
     margin-top: 16px;
     margin-bottom: 16px;
  }
  .session_img {
     margin-top: 70px;
  }
  .session_grid{
     max-width: 1440px;
     margin: auto;
  }
  .password_input input + .MuiInputAdornment-root button{
     color: #B5B9CA !important;
  }
  .password_input input:focus-visible + .MuiInputAdornment-root button {
     color: #222 !important;
  }
  .password_input input + .MuiInputAdornment-root button:hover {
     color: #222 !important;
  }
  .session_page button.filled_btn{
     width: 100% !important;
     min-width: unset !important;
     font-family: Inter;
     font-family: 700;
  }
  .sucess_greeting{
     font-size: clamp(1.0625rem, 0.8836rem + 0.3817vi, 1.25rem);;
     color: #6E6E6E;
     font-family: Inter;
     font-weight: 400;
     line-height:28px;
  }
  .forget_link {
     margin-top: 10px !important;
  }
  .sucess_greeting span{
     font-weight: 700;
  }
  .login_logo{
     position: relative;
     top: 8px;
  }
 
  small{
     display: block;
  }
  @media screen and (max-width: 1440px){
     .session_content{
         width: 85%;
    }
     .session_card{
         margin: auto !important;
    }
  }
  @media screen and (min-width:1920px){
     .session_grid{
         max-width: 1440px;
         margin: auto;
    }
  }
  @media screen and (max-width:992px) {
     .session_image_content{
         display: none;
    }
     .session_grid{
         justify-content: center;
    }
     .session_card{
         width: 470px !important;
         height: 680px !important;
    }
  }
  @media screen and (min-width:993px) and (max-width:1380px){
     .session_card{
         width: 90% !important;
         height: 685px !important;
    }
     .session_img svg{
         height: 100% !important;
         width: 100% !important;
    }
  }
  @media screen and (max-width:1056px) {
    
     input{
         font-size: 15px !important;
    }
   
     .Otp_input_box.emailcode.responisve{
         gap: 4px !important;
    }
     .mb-30{
         margin-bottom: 15px !important;
    }
    .current_user_session{
     display: grid;
     place-content: center;
     height: 100% !important;
    }
  
  
  }
  @media screen and (max-width:800px) {
     .otp_input.small{
         width: 64px !important;
    }
     .css-limwuw-MuiPaper-root-MuiPopover-paper{
         top: 70px !important;
    }
     .responsivecard{
         overflow-x: scroll !important;
    }
     .sidebar_header, .sideform_container{
         padding: 18px !important;
    }
     .primary_btn.w-360 {
         width: 250px !important;
    }
     .confrm_btn{
         width: 176px !important;
         height: 50px !important;
    }
     .flex-container {
         display: block !important;
    }
     .basic-single.select_box.select_box{
         width: 100% !important;
    }
  }
  @media screen and (max-width: 1056px) {
     .resposive_steppper {
         flex-direction: row !important;
         white-space: nowrap;
         overflow-x: scroll;
         width: 628px;
         margin-bottom: 20px;
    }
     .MuiStepIcon-root.Mui-completed {
         margin-left: 12px;
    }
     .MuiStep-root {
         padding: 20px 16px;
    }
     .login_bg {
         height: 100vh !important;
    }
     .mobile_svg, .charcter_svg {
         display: none !important;
    }
    
    
     input {
         font-size: 15px !important;
    }
    
    
    
   .login_card {
         margin-bottom: 0px !important;
         width: 450px !important;
    }
     .login_btn.mt_80 {
         margin-top: 40px !important;
    }
     .Otp_input_box.emailcode.responisve {
         gap: 4px !important;
    }
     .credits_logo {
         margin-top: 40px;
    }
     .mb-30 {
         margin-bottom: 15px !important;
    }
  }
  @media screen and (max-width: 768px) {
     .admin_greetings {
         font-size: 20px;
    }
  }
  
  .justify-between{
   justify-content: space-between !important;
  }
  .h-100{
   height: 100% !important;
  }
  .flex-center-col{
   display: flex;
   align-items: center;
   /* justify-content: center; */
   flex-direction: column;

  }