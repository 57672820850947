@import "./session.css";
@import "./status.css";
@import "./form.css";
@import "./utils.css";
@import "./button.css";
@import "./table.css";
@import "./navbar.css";
/* Responsive utility classes */
@media (max-width: 768px) {
  .flex-column-mobile {
    flex-direction: column;
  }
}
.d-none {
  display: none !important;
}
.CmtLayout-content.nimalaan_content {
  margin: 0px !important;
}
.roles .MuiAvatar-root {
  width: 60px !important;
  height: 60px !important;
}
.avatar-p {
  font-size: 18px;
  font-weight: 500 !important;
  margin-top: 10px;
}
.div-height {
  height: 1.5rem;
}
.roles .css-17pmaar-MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}
.roles .css-17pmaar-MuiCardContent-root {
  padding: 20px;
}
.action-set {
  font-size: 20px !important;
}
.action-set .css-i4bv87-MuiSvgIcon-root {
  width: 25px !important;
  height: 25px !important;
}
.key-icon {
  margin-top: 1rem;
  margin-block: auto;
  margin-left: 9rem;
}
.css-1vqirym-MuiButtonBase-root-MuiSwitch-switchBase {
  color: #7352c7 !important;
}
.css-1yjjitx-MuiSwitch-track {
  opacity: 0.15 !important;
}
.css-1vqirym-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  opacity: 0.38 !important;
}
.mw-25 {
  min-width: 25% !important;
}
.mw-36 {
  min-width: 36% !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  overflow-y: auto;
  max-height: 20rem;
}
.css-8t49rw-MuiStepConnector-line {
  min-height: 0px !important;
  border-left-width: 2px !important;
}
/*.css-14yr603-MuiStepContent-root {*/

.MuiStepContent-root {
  margin-left: 23px !important;
  border-left-width: 2px !important;
  min-height: 6.5rem !important;
}

/*.css-1pe7n21-MuiStepConnector-root {*/

.MuiStepConnector-vertical {
  margin-left: 23px !important;
}

/*.css-14sza3e-MuiStepLabel-root {*/

.MuiStepLabel-vertical {
  padding: 0px !important;
}
.activity .css-1661lxv-MuiTableCell-root-MuiTablePagination-root {
  border-bottom: none;
}
.notify .css-1axmsfc-MuiAvatar-root {
  background-color: #f1f5f9;
}
.css-1yt7yx7-MuiLoadingButton-loadingIndicator {
  color: #6200ee !important;
}
.update-role .css-u-3zwh2-MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: 0;
}
.note {
  color: #6366f1;
  margin-top: 0px;
  margin-right: 5px;
}
.role-set {
  padding-right: 53%;
}
.permission-list .css-sxs2hi-MuiButtonBase-root-MuiIconButton-root {
  font-size: 1rem;
}
@media screen and (max-width: 1268px) {
  .css-8qphhn {
    margin-left: 32rem !important;
  }
}
@media screen and (min-width: 1440px) {
  .css-8qphhn {
    margin-left: 43rem !important;
  }
}
.profile
  .css-em1ewv-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.MuiLoadingButton-loading {
  color: #fff !important;
}
.view-qr {
  margin-left: auto;
  margin-top: 1rem;
}
.css-xebciq-MuiToolbar-root {
  background-color: #fff !important;
}
.login_bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.page_header {
  font-size: 24px !important;
  font-weight: 600 !important;
  font-family: "Inter" !important;
  color: #101010 !important;
}
.css-deq2aw-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #f1a139 !important;
  font-weight: 600 !important;
}
.css-deq2aw-MuiButtonBase-root-MuiButton-root {
  font-weight: 600 !important;
}

.page_header_option {
  display: flex;
  align-items: center;
}
.menuItem {
  color: #666666 !important ;
}

.sidebar_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px;
  border-bottom: 1.4px solid #e7e6ec;
}
.sideform_container {
  padding: 22px;
}
.sidebar_header h1 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.sidebar_header svg {
  width: 26px;
}
.dlfex-justify-between-align-center {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.findifce {
  color: #2168f0;
  font-size: 20px;
  font-weight: 600 !important;
  cursor: pointer;
}
.beneficary_details {
  border-collapse: collapse;
}
.beneficary_details td {
  padding: 15px 22px;
  border-bottom: 1.4px solid #e7e6ec;
}
.beneficary_details .table_label {
  font-size: 16px;
  font-family: "inter", "sans-derif";
  font-weight: 500;
  min-width: 200px !important;
  color: #666666;
  letter-spacing: 0.8px;
}
.beneficary_details .table_value {
  font-size: 16px;
  font-family: Inter "sans-derif";
  font-weight: 500;
  color: #333333;
}

.confrm_btn {
  background-color: #f1a139 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  padding: 8px 12px;
  color: #fff !important;
  width: 200px;
  height: 56px;
}
.cancel_payment {
  font-size: 18px;
  font-weight: 600;
  font-family: Inter "sans-serif";
  color: #333333;
  margin-left: 20px;
  cursor: pointer;
}
.confirmation_buttons {
  margin: 60px auto;
}
.verify_greet {
  font-size: 18px;
  font-weight: 600;
  font-family: Inter "sans-serif";
  color: #333333;
}
.back_bene {
  font-size: 18px;
  font-weight: 600;
  font-family: Inter "sans-serif";
  color: #2168f0;
}
.verification_sucess {
  margin-top: 50px;
  margin-bottom: 50px;
}
.tab_title {
  font-size: 22px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  color: #101010 !important;
  font-family: Inter "sans-serif";
}
.tabs_section .css-uiyfm9-MuiTabs-indicator {
  background-color: #101010;
  height: 3px;
}
.bold_span {
  color: #101010 !important;
}
.sidnav_links_item span {
  font-size: 15px !important;
  font-weight: 500;
}
.accont_info {
  padding: 22px;
  border-bottom: 1.4px solid #e7e6ec;
}
.accont_info p {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.accont_info h6 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #666666;
}
.statement_info {
  padding: 22px;
}
.statement_info p {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 4px;
}
.statement_info .arrow {
  color: #666666;
  font-size: 20px;
  margin-left: 20px;
}
.statement_info h6 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #2168f0;
}
.quick_acess {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.qucik_access_card {
  padding: 22px;
}
.qucik_access_card h5 {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.quick_acess p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.icon_bg {
  background: #f5f4f9;
  height: 62px;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
}
.recent_transaction_card {
  overflow-y: scroll !important;
}
.recent_transaction_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.recent_transaction_header p {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  margin: 0;
}
.recent_transaction_header h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #2168f0;
}
.recent_months p {
  padding: 12px 22px;
  background: #f5f4f9;
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
.result_logo {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.result_card {
  border-bottom: 1.4px solid #e7e6ec;
  padding: 16px 0px;
}
.result_card p {
  margin: 0;
}
.result_card h4 {
  margin: 0;
}
.result_logo_bg {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7e6ec;
}
.main_results {
  padding: 0px 20px;
}
.resultcard_details h4 {
  font-size: 16px;
  font-weight: 500;
  color: #121212;
}
.resultcard_details p {
  font-size: 14px;
  font-weight: 500;
  color: #999999;
}
.reasult_mony {
  font-size: 16px;
  font-weight: 600;
}
.div_container {
  display: flex !important;
  align-items: center !important;
}
.cursor {
  cursor: pointer !important;
}
/** navbar top header **/
header .MuiToolbar-root {
  border-bottom: 1.4px solid #e4e4e4;
  box-shadow: 2px 2px 5px 0px #c2c2c21a;
  background-color: #fff !important;
}
/* common header */
.common_header h2 {
  margin: 0;
}
.common_header {
  padding-bottom: 20px;
}
/* admin header */
.admin_nav_menus ul li .settings.active {
  background-color: #fff !important;
}
.settings.active::after {
  display: none !important;
  color: #ffffff;
}

/* .drpdownlinks a{
    padding: 6px 12px !important;
    font-size: 16px !important;
}
*/
/* .drpdownlinks a:hover{
    color: #EC8605;
}
 */
.logout_admin {
  background: none !important;
}
.logout_admin:hover {
  background-color: none !important;
}
.alert-icon {
  color: #ee0800;
}
.alert-icon:hover {
  color: red;
}
.blue_txt {
  color: blue;
  text-decoration: none !important;
}
/* .MuiPopover-paper {
  min-width: 190px !important;
 
} */
.css-5ygetb-MuiPaper-root-MuiCard-root {
  background-color: #f5f5f7e8 !important;
  min-width: 280px !important;
}
.MuiStepLabel-root {
  margin-left: 12px !important;
}
.MuiStepLabel-root .MuiSvgIcon-root {
  background-color: #f5f5f7e8 !important;
}

.stepContent {
  padding: 15px !important;
}
.stepContent span.followtxt {
  display: block;
}
#fade-menu div.MuiPopover-paper {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  min-width: 100px !important;
}
.custom_popover .MuiPopover-paper {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
}
h2.heading {
  margin-bottom: 1.5rem !important;
}
/* customized toaster */
.Toastify__toast-container--top-right {
  right: 8em !important;
}
.Toastify__toast {
  width: 420px !important;
}
.Toastify__toast {
  padding: 0 !important;
  border-radius: 10px !important;
}
.Toastify__toast-icon {
  height: 100% !important;
  width: 55px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.Toastify__toast-icon svg {
  height: 24px !important;
  width: 24px !important;
  margin: auto !important;
}
.Toastify__toast-body {
  padding: 0px !important;
  margin: 0px !important;
}
.Toastify__toast--success .Toastify__toast-icon {
  background-color: #6ac649 !important;
}
.Toastify__toast--error .Toastify__toast-icon {
  background-color: #e46767 !important;
}
.Toastify__toast--info .Toastify__toast-icon {
  background-color: #4090ef !important;
}
.Toastify__toast--warning .Toastify__toast-icon {
  background-color: #e0ad46 !important;
}
.Toastify__toast-body > div:last-child {
  color: #1f2328;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
}
.Toastify__close-button {
  align-self: center !important;
  margin-right: 10px !important;
}
.Toastify__toast-container {
  z-index: 99999 !important;
}
.CmtLayout-content.nsdl_content {
  padding: 0px !important;
}
.CmtLayout-main {
  margin-left: 0px !important;
}

.gif_cont {
  height: 200px;
  width: 200px;
  margin: 0px auto;
}
.login_gif {
  height: 160px;
  width: 160px;
  margin: 0px auto;
}

.loader_layout {
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
  background-color: #1010102a;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadr_box {
  height: 120px;
  width: 120px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -2px 1px 4px 0px rgba(0, 0, 0, 0.25);
}
.order_tracker_loader {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(203, 203, 203, 0.368); */
  font-size: 16px;
  font-weight: 500;
}
.new_set_card {
  background-color: #a2b2c312 !important;
  border-radius: 10px !important;
  box-shadow: -1px 0px 2px -1px rgba(0, 0, 0, 0.15) !important;
  border: 1.4px solid #e7e6ec !important;
}
.stepCard {
  width: 80% !important;
}
.enq_titlt {
  font-size: 16px;
  color: #333 !important;
  font-weight: 600 !important;
}
.no-records {
  height: 100vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no-records {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.err {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes notification-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.notification-icon-animated {
  /*  animation: notification-pulse 1s infinite;*/
  animation: ring 2.5s ease infinite;
  transform-origin: top center;
  font-size: 21px;
}

@keyframes ring {
  0% {
    transform: rotate(35deg);
  }
  12.5% {
    transform: rotate(-30deg);
  }
  25% {
    transform: rotate(25deg);
  }
  37.5% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(15deg);
  }
  62.5% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

#menu .css-zxl11n-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  min-width: 190px !important;
}

@media screen and (max-width: 650px) {
  .admin_responsive_links li a.active {
    width: 100%;
    padding: 17px !important;
  }
  /* .admin_responsive_links li a {
        width: 100%;
    } */
}

.pagination_selectbox {
  /* Define your custom styles here */
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #fff;
  color: #333;
  outline: none; /* Remove default focus outline */
  cursor: pointer; /* Show pointer cursor on hover */
}

/* Optionally, you can style the dropdown arrow */
.pagination_selectbox::after {
  content: "\25BC"; /* Unicode for down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Make the arrow non-clickable */
}

.pagination_selectbox option {
  /* Define your custom styles for options here */
  background-color: #fff;
  color: #333;
  padding: 5px 10px !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}

.pagination_selectbox option:hover {
  /* Define hover styles for options */
  background-color: #f0f0f0;
}

.pagination_selectbox option:selected {
  /* Define selected option styles */
  background-color: #e0e0e0 !important;
  color: #000 !important;
}
.custom-menu-paper-select {
  min-width: 350px !important;
  /*max-width: 350px !important;*/
}
.placeholder_txt {
  color: grey !important;
}
@media screen and (max-width: 1380px) {
  .responsive_card {
    overflow: auto !important;
  }
  .common_table td {
    white-space: nowrap !important;
    padding: 2px 8px !important;
  }
  .common_table th {
    white-space: nowrap !important;
    padding: 2px 8px !important;
  }
  .div_container h2 {
    white-space: nowrap !important;
    font-size: 18px;
  }
  .div_container button {
    font-size: 15px !important;
  }
  .search {
    width: 210px !important;
  }
}
.search-icon {
  cursor: pointer !important;
}

.admin_navbar {
  position: sticky !important;
  top: 0 !important;
  z-index: 999 !important;
}
.admin_nav_menus ul .dropdown_hover .drpdownlinks a.active {
  background-color: transparent !important;
  opacity: 1 !important;
  border: none !important;
  /* color: #ec8605 !important;*/
}

.admin_nav_menus ul .dropdown_hover .drpdownlinks a.active::after {
  display: none;
}
.dropdown_hover {
  position: relative !important;
}
.dropdown_hover:hover .drpdownlinks {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}
.devider_head {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 22px;
  color: #666666;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 10px;
}
.dropdown_hover .drpdownlinks {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 0px 0;
  top: 100%;
  transform: translateY(50px);
  left: 0;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 111;
  transition: 0.4s all;
  border: 1.4px solid #e7e6ec;
  border-radius: 10px;
}
.logout_admin {
  background: none !important;
}
.logout_admin:hover {
  background-color: none !important;
}
/* admin */
.admin_navbar {
  background-color: #ffffff !important;
  height: 80px !important;
  display: block !important;
  color: #000 !important;
}
.admin_nav_menus ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 80px;
  margin-left: 60px !important;
  margin: 0px;
}
.admin_nav_menus ul li {
  list-style: none;
  margin-right: 30px;
  height: 80px;
}
.admin_nav_menus ul li a {
  white-space: nowrap;
  text-decoration: none !important;
  font-size: 17px;
  font-weight: 600;
  color: #666666;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  height: 100%;
}
.admin_nav_menus ul li a.active {
  /*  background: #fdf6ea !important;*/
  color: #186cba !important;
}
.admin_nav_menus ul li a.active:hover {
  color: #186cba !important;
}
.profile_name {
  color: #4d4d59 !important;
  font-size: 16px !important;
}
.admin_nav_menus ul li a.active::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  left: 0;
  bottom: 0;
  /* background-color: #ec8605; */
  color: #ffffff;
}

.admin_nav_menus ul li.dropdown_hover:hover {
  background-color: none !important;
  color: #ffffff;
}
.admin_nav_menus ul li :hover,
.admin_nav_menus ul li.dropdown_hover a.settings:hover,
.admin_nav_menus ul li.dropdown_hover a.settings .drpdownlinks p a:hover {
  color: #4d4d59 !important;
}
.drpdownlinks p a:hover {
  background-color: #ffffff !important;
  color: rgb(24 108 186) !important;
}

.admin_nav_menus ul li.dropdown_hover a.settings:hover {
  /*  color: rgb(245, 142, 7) !important;
  background-color: #ffffff !important; */
}

@media screen and (max-width: 1366px) {
  .admin_nav_menus ul li a {
    font-size: 16px;
    padding: 20px;
  }
  .admin_nav_menus ul {
    margin-left: 0px;
  }
  .admin_nav_menus ul li {
    margin-right: 20px;
  }
}
.search-icon{
    cursor:pointer !important;
}

.view-upload img{
    width: 80px !important;
}


/* View jobs */

.job-view-section{
  margin: 25px;
}

.job-view-section .post_date{
  font-size: 13px;
  font-family: var(--Roboto);
  line-height: 28px;
  font-weight: 700;
  color: #717171;
}
.job_overview{
  margin-top: 20px;
}
.job_overview p{
  font-size: 16px;
  font-family: var(--Roboto);
  line-height: 28px;
  font-weight: 500;
  color: #717171;
  margin-top: 0px;
  margin-bottom: 10px;
}
.job_overview p:last-child{
  margin-bottom: 0px;
}
.job_overview p span{
  
  font-weight: 600;
  color: #333333;
}
.job_view_description{
  margin-top: 30px;
}

.job_view_description h5, .applicant-title{
  font-size: 18px;
  line-height: 29px;
  font-weight: 600;
  font-family: var(--heebo);
  color: #333333;
  opacity: 85%;
  margin: 0px;

}



.job-view-btn{
  all: unset;
  width: 221px;
  height: 40px;
  border-radius: 5px;
  background: var(--primary);
  color: #fff;
  font-family: var(--Open-sans);
  font-weight: 700;
  font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
  text-align: center;
  margin-top: 40px;
}
.job-view-btn:hover{
  background-color: var(--primary-hover);
}
.job-info-section{
  margin: 25px;
}
.job-info-overview{
  margin-top: 20px;
}
.job-info-section-container{
margin-bottom: 20px;
}
.job-info-section-container span{
  font-size: 16px;
  font-family: var(--Roboto);
  line-height: 32px;
  font-weight: 400;
  color: #666666;
  /* opacity: 75%; */
}
.job-info-section-container h5{
  font-size: 16px;
  font-family: var(--Roboto);
  line-height: 32px;
  font-weight: 600;
  color: #333333;
 
  margin: 0px;
  margin-top: -4px;
}
.job_detail_main{
  margin-top: 50px;
}
.carrier_banner .job-view-btn{
  margin-top: 14px !important;
}
.job_title{
  margin-top: 0;
  font-size: 18px;
  color: #333333;
}
.job_keys_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.job_keys_cont p {
  margin: 0px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  font-family: var(--Roboto);
  color: #131313;
  opacity: 75%;
}
.job_keys{
  display: flex;
  align-items: center;
  gap: 30px;
}

.applicant-card{
  margin-top: 10px;
  /* min-height: 350px; */
  overflow: auto !important;
}
.applicant-title{
  font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
  line-height: 29px;
  font-weight: 700;
  font-family: var(--heebo);
  color: #131313;
  opacity: 85%;
  margin: 0px;
  padding-top: 20px;
  padding-left: 10px;
}

.job_applicant{
  margin: 24px;
}
.applicants{
  display: flex;
  align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #f1f1f1;
}

.appliyed-date{
  font-size: 12px;
  color: #666;
  margin: 0;

}

.pl-12{
  padding-left: 12px;
}

.applicant-member{
  margin-top: 12px;
}

.applicants img{
  width: 46px;
  height: 46px;
}
.applicants .applicant-name{
  font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.10rem);
  font-family: var(--Roboto);
  line-height: 28px;
  font-weight: 500;
  color: #666;
  /* margin-top: 8px; */
  margin-bottom: 0px;
  cursor: pointer;
  width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
}
.chip-group{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 12px;
}
.chip_list{
  background: #f6f7fa !important;
  border: 1px solid #ccf3d6;
  padding: 2px 20px 4px 20px !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
  line-height: 28px !important;
}
.w-full{
    width: 100% !important;
}
.image_uploader{
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #666666;
    cursor: pointer;
    position: relative;
}

.uploader_content{
    height: 90px;
    width: 90px;
    position: relative; 
}
.uploader_content img{
    height: 100%;
    width:100%;
    object-fit: cover;
    margin-right: 20px;
}
.uploader_content video{
    height: 100%;
    width:100%;
    object-fit: cover;
    margin-right: 20px;
}
.uploader_row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.w-80{
    width: 40%;
}

.time_picker_row{
    display: flex;
    align-items: center;
 
    flex-direction: row !important;
}
.close_icon{
    position: absolute;
    top: -8px;
    right: -8px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;

}
.ql-editor {
    height: 250px;
}
.form_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ml-12{
  margin-left: 12px !important;
}
.date_picker_create div.css-1lemicc-MuiInputBase-root-MuiOutlinedInput-root{
    width: 339px !important;
    min-width: unset !important;
}
.table_image{
    height: 60px;
    width: 60px;
    object-fit: cover;
    margin: 10px 0px;
}
.mt-50{
    margin-top: 50px;
}
.upload_notes{
    margin-top: 20px;
}
.upload_notes p{

    font-size: 13px;
    font-family: 500;
    margin-bottom: 0px;
    margin-top: 6px;
    color: #666666;
    line-height: 18px;


}

.upload_notes p span{
    font-weight: 700;
}

.searchWrapper input{
  height: 41px !important;
}

.searchWrapper input::placeholder{
  font-size: 14px !important;
  color: #666666 !important;
}

.border_red .searchWrapper {

  border-color: red !important;

}
.MuiFormHelperText-sizeMedium , .MuiFormHelperText{
  color: red  !important;
}

.MuiFormHelperText-root{
  color: red  !important;
}
.quill {
  height: auto !important;
}

.searchWrapper .chip{
  border-radius: 20px !important;
  background: #196eb7 !important;
  color: #fff !important;
  padding: 4px 12px !important;
  gap: 9px !important;
  font-size: 14px !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
  line-height: 20px !important;
}
.MuiChip-root{
  background: #196eb7 !important;
  color: #fff !important;
}
.MuiChip-root .MuiSvgIcon-root{
  color: rgb(255 255 255) !important;
  font-size: 14px !important;
  font-weight:500 !important
}
.job_tag{
  font-family: "Inter" !important;
}

.form_new_style.date_error  .MuiInputBase-root{
  border: 1px solid red !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.link{
  cursor: pointer;
}

.gallary {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  width: 100%;
}
.no_gallary{
    width: 100%;
    display: flex;
    justify-content: center;
}

.video_thumb{

  transition: .3s ease-in-out;
  overflow: hidden;
}

.video_thumb .overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.7;
  z-index: 99;
  top: 0;
  left: 0;
  
}

.video_thumb .play_icon{
  color: white;
  position: absolute;
  font-size: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.draggable-image {
  width: 150px;
  height: 150px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  position: relative;
}

.draggable-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.new_gallery_bg .close-icon{
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    z-index: 99;
    cursor: pointer;
}
.new_gallery_bg .close-icon svg{
  fill: white;
}
.submit-btn-sec{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.no_data_message{
  text-align: center;
}

.no_data_message img{
  width: 60%;
}

.no_data_message p{
  color: #021E39;
  font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.5rem);
  font-family: var(--heebo);
  line-height: 28px;
  font-weight: 400;
  padding: 12px 0;
}
#alert-dialog-title{
  margin-bottom: 0;
  font-weight: 600;
}
.radio-grp{
  align-items: center;
  margin-bottom: 20px;
}
#demo-row-radio-buttons-group-label{
  align-items: center;
  padding-right: 20px;
  color: #000000;
  font-size: 18px;
}
.submit-btn-section{
  display: flex;
  justify-content: center;
}
.event-content{
  margin: 30px;
}

.event-content h1{
  font-size: clamp(1.25rem, 0.9466rem + 1.6181vi, 2.5rem);
  font-family: var(--heebo);
  font-weight: 700;
  color: #021E39;
  margin-top: 0px;
  margin-bottom: 10px;
}

.event_timing{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.event_location p{
  font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
  line-height: 28px;
  font-weight: 500;
  font-family: var(--Roboto);
  color: #021E39;
  text-wrap: balance;
  margin-bottom: 0px;
  margin-top: 0;
  padding-left: 8px;
}

.event_location{
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding-bottom: 20px;
}

.event-descrp{
  margin: 20px 0;
}

.event-descrp p{
  font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
  line-height: 28px;
  font-weight: 500;
  font-family: var(--Roboto);
  text-wrap: balance;
  margin-bottom: 0px;
  color: #666666;
  padding-bottom: 26px;
  white-space: normal;
}

.event-descrp ul{
  padding-left: 18px;
}

.event-descrp li{
  font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
  line-height: 28px;
  font-weight: 500;
  font-family: var(--Roboto);
  text-wrap: balance;
  margin-bottom: 0px;
  color: #515151;
}
.MuiAutocomplete-popper{
  border: 2px solid #cccc !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.event_banner{
  margin-top:10px;
  margin-bottom: 30px;
}

.event_banner img{
  width: 100%;
  height: 350px;
  border-radius: 15px;
  object-fit: cover;
}
.upload_loader{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  background-color: #000000ba;
  color: #fff !important;

}
.cursor_change{
  cursor: move;
}